import { Swiper } from 'swiper'
import { Navigation, Pagination, Autoplay, Grid } from 'swiper/modules';

import SimpleParallax from 'simple-parallax-js'
import Swal from 'sweetalert2'
window.Swal = Swal;
import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

(function ($, undefined) {
    'use strict';

    var briefTrigger = $('#modalBrief');
    briefTrigger.on('click', function () {
        openPopup('#briefingModalWindow');
        var briefingSlider = new Swiper('#briefingSlider', {
            modules: [Navigation, Pagination],
            direction: 'horizontal',
            slidesPerView: 1,
            centeredSlides: true,
            loop: false,
            watchOverflow: true,
            autoHeight: true,
            pagination: {
                el: '#briefDots',
                type: 'bullets'
            },
            navigation: {
                nextEl: '#briefNavNext',
                prevEl: '#briefNavPrev'
            }
        });
        return false;
    });

    var swiperMainPageBanners = new Swiper('#mainPageSlider', {
        modules: [Navigation, Pagination, Autoplay],
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        speed: 1000,
        effect: 'fade',
        // watchOverflow: true,
        autoplay: {
            delay: 6000
        },
        fadeEffect: {
            crossFade: false
        },
        pagination: {
            el: '#mainSliderPagination',
            type: 'bullets',
            clickable: true
        }
    });

    var swiperOtherPageBanners = new Swiper('#otherPageSlider', {
        modules: [Navigation, Pagination, Autoplay],
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: false,
        speed: 1000,
        effect: 'fade',
        watchOverflow: true,
        autoplay: {
            delay: 6000
        },
        fadeEffect: {
            crossFade: false
        },
        pagination: {
            el: '#otherSliderPagination',
            type: 'bullets',
            clickable: true
        }
    });

    var mainSlider = new Swiper('#mainSlider', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        direction: 'horizontal',
        autoHeight: false,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '#msc-arrow-next',
            prevEl: '#msc-arrow-prev',
        },
        pagination: {
            el: '#msc-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">0' + (index + 1) + '</span>';
            }
        }
    });

    $('#main-splash img').attr('src', $('.swiper-slide:eq(' + mainSlider.activeIndex + ')').attr('data-banner-image'));
        mainSlider.on('transitionEnd', function () {
        $('#main-splash img').attr('src', $('.swiper-slide:eq(' + mainSlider.activeIndex + ')').attr('data-banner-image'));
    });

    var helperSlider = new Swiper('#reactorHelper', {
        slidesPerView: 'auto',
        direction: 'horizontal',
        loop: true,
        spaceBetween: 0,
        watchOverflow: true,
        centeredSlides: true,
        breakpoints: {
            990: {
                centeredSlides: false,
                slidesPerView: 4,
                loop: false
            }
        },
    });

    var winnersSlider = new Swiper('#reactorWinners', {
        modules: [Navigation, Pagination],
        slidesPerView: 'auto',
        direction: 'horizontal',
        loop: true,
        spaceBetween: 0,
        watchOverflow: true,
        centeredSlides: true,
        breakpoints: {
            990: {
                centeredSlides: false,
                slidesPerView: 3,
                loop: true
            }
        },
        navigation: {
            nextEl: '#winCtrlNext',
            prevEl: '#winCtrlPrev'
        },
        pagination: {
            el: '#winnersPagination',
            type: 'fraction'
        },
    });

    var supportSlider = new Swiper('#supportSlider', {
        modules: [Navigation, Pagination, Grid],
        slidesPerView: 3,
        spaceBetween: 12,
        loop: true,
        direction: 'horizontal',
        navigation: {
            nextEl: '#supportCtrlNext',
            prevEl: '#supportCtrlPrev'
        },
        pagination: {
            el: '#supportPagination',
            type: 'fraction'
        },
        breakpoints: {
            480: {
                slidesPerView: 2,
                grid: {
                    rows: 3,
                    fill: 'row',
                },
            },
            768: {
                slidesPerView: 3,
                grid: {
                    rows: 3,
                    fill: 'row',
                },
            }
        }
    });

    var eventsSlider = new Swiper('#eventsSlider', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        direction: 'horizontal',
        loop: true,
        spaceBetween: 0,
        // watchOverflow: true,
        centeredSlides: false,
        navigation: {
            nextEl: '#eventsCtrlNext',
            prevEl: '#eventsCtrlPrev'
        },
        pagination: {
            el: '#eventsPagination',
            type: 'fraction'
        },
        breakpoints: {
            480: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            1300: {
                slidesPerView: 4
            }
        }
    });

    function addZeroes(curSlideNum)
    {
        // Add zeroes before number of current slide.
        let zeroesCount = String(window.slidesTotalCount).length - String(curSlideNum).length;
        while (zeroesCount)
        {
            curSlideNum = '0' + curSlideNum;
            zeroesCount--;
        }
        // Display number of current slide.
        $('.project-presentation .sw-pag-frac-current').text(curSlideNum);
    }

    let presNode = $('.project-presentation');
    window.slidesTotalCount = presNode.find('.swiper-slide').length;

    var presentationSlider = new Swiper(presNode.find('.swiper-container').get(0), {
        modules: [Navigation, Pagination],
        pagination: {
            type: 'progressbar',
            el: presNode.find('.presentProgressbar').get(0),
        },
        navigation: {
            prevEl: presNode.find('.prprc__prev').get(0),
            nextEl: presNode.find('.prprc__next').get(0),
        },
        allowTouchMove: false
    });

    presNode.find('.sw-pag-frac-total').text(window.slidesTotalCount);

    // Set number of current slider as 1.
    addZeroes(1);

    // Change number of current slide when navigation button is clicked.
    presNode.find('.prprc__next').on('click', function()
    {
        let num = $(this).parent().find('.sw-pag-frac-current').text();
        addZeroes(Number(num) + 1);
    });
    presNode.find('.prprc__prev').on('click', function()
    {
        let num = $(this).parent().find('.sw-pag-frac-current').text();
        addZeroes(num - 1);
    });

    var evalSwiper = new Swiper('#evalSwiper', {
        modules: [Navigation, Pagination],
        direction: 'horizontal',
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        speed: 1000,
        /* effect: 'fade',
         fadeEffect: {
         crossFade: false
         },*/
        pagination: {
            el: '#evalPages',
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return 'Слайд <span class="' + currentClass + '"></span> из <span class="' + totalClass + '"></span>';
            }
        },
        navigation: {
            nextEl: '#ecb-next',
            prevEl: '#ecb-prev'
        }
        /*autoplay: {
         delay: 5000,
         waitForTransition: true
         }*/
    });

    var galleryThumbs = new Swiper('#project-gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true
    });

    var galleryTop = new Swiper('#project-gallery', {
        modules: [Navigation, Pagination],
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        thumbs: {
            swiper: galleryThumbs
        }
    });

    var achievementSectionSlider = new Swiper('#achievement-section-slider', {
        modules: [Navigation, Pagination],
        spaceBetween: 10,
        slidesPerView: 1,
        direction: 'horizontal',
        navigation: {
            prevEl: '#assNaviBtnPrev',
            nextEl: '#assNaviBtnNext',
        },
        pagination: {
            el: '#ass-navi-fraction',
            type: 'fraction',
        },
        renderFraction: function (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' +
                ' of ' +
                '<span class="' + totalClass + '"></span>';
        },
        breakpoints: {
            768: {
                slidesPerView: 3
            },
            990: {
                slidesPerView: 2
            },
            1310: {
                slidesPerView: 3
            }
        }
    });

    $('#main-search, #search-resources').on('input', function () {
        if ($(this).val() !== '') {
            $(this).closest('.site-menu__search').find('.search-reset').addClass('active');
        } else {
            $(this).closest('.site-menu__search').find('.search-reset').removeClass('active');
        }
    });

    $('.search-reset').on('click', function () {
        $(this).closest('.site-menu__search').find('#main-search').val('');
        $(this).closest('.site-menu__search').find('#search-resources').val('');
        $(this).removeClass('active');
    });
    $('.js_close_mobilemenu').on('click', function () {
        $('#main-search-show-result').addClass('none');
        $('#none-main-search').addClass('none');
        $('.menu-horiz-counter-no-dropdown').hide();
        $('#main-search, #search-resources').val('');
        $htmlbody.removeClass('scrollOff');
    });

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function setCookie(name, value, options = {}) {
        options = {
            path: '/',
            // при необходимости добавьте другие значения по умолчанию
            ...options
        };

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    if (getCookie('star-wars-test-result-id') && $('header[data-user-id]').attr('data-user-id')) {
        $.ajax({
            method: "POST",
            url: "/api/star_wars_result_of_user",
            data: {
                'result_id': getCookie('star-wars-test-result-id'),
                'user_id': $('header[data-user-id]').attr('data-user-id')
            },
            success: function (response) {
                if (response.status == true) {
                    setCookie('star-wars-test-result-id', "", {
                        'max-age': -1
                    });
                    window.location.href = '/star-wars-test/result/' + response.result_id;
                }
            }
        });
    }


    // Local variables
    var resizeTimer,
        $window = $(window),
        $htmlbody = $('html, body');

    // +section start: parallax
    /*var imageplx = document.getElementById('main-splash-img');
    var splx = new SimpleParallax(imageplx, {
        orientation: 'down',
        delay: 0,
        scale: 1.5
    });*/
    // -section end: parallax

    window.onscroll = function (e) {
        var h = 56.4;
        var sTop = window.pageYOffset;

        if (sTop < 700) {
            var blockPad = 60 - (sTop * 0.07);
            $('#main-splash').css('padding-bottom', blockPad + '%');
        }

        if(sTop>700){
            $('#main-splash').css('padding-bottom', '10%');
        }
    };

    // $(window).scroll(() => {
    //     let scroll = $(window).scrollTop() + $(window).height();
    //     const offset = $('.main-page-banner-box').offset().top
    // })

    // +section start: sticky header on scrol up
    var didScroll,
        lastScrollTop = 0,
        delta = 5,
        navHeader = $('header'),
        navbarHeight = navHeader.outerHeight();

    $(window).scroll(function () {
        didScroll = true;
    });

    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(window).scrollTop();

        if (Math.abs(lastScrollTop - st) <= delta)
            return;
        if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            if (!$('.usrprof-menu').hasClass('usrprof-search-menu-open')) {
                navHeader.removeClass('nav-down').addClass('nav-up');
                $('.aside-sticked').removeClass('nav-down').addClass('nav-up');
            }
        } else {
            // Scroll Up
            if (st + $(window).height() < $(document).height()) {
                navHeader.removeClass('nav-up').addClass('nav-down');
                $('.aside-sticked').removeClass('nav-up').addClass('nav-down');

            }
        }
        lastScrollTop = st;
    }
    // -section end: sticky header on scrol up

    // +section start: mobile menu
    var menuToggle = $('#menuToggle'),
        mobileMenu = $('#newMobileMenu');

    function menuClose() {
        menuToggle.removeClass('openedMenuBtn');
        mobileMenu.removeClass('openedMenu');
        $htmlbody.removeClass('scrollOff');
    }

    function menuOpen() {
        menuToggle.addClass('openedMenuBtn');
        mobileMenu.addClass('openedMenu');
        $('.usrprof-menu').removeClass('usrprof-search-menu-open');
        $('.usrprof-menu').removeClass('usrprof-menu-open');
        $('.auth-arrow-wrapper .auth-arrow').removeClass('active');
        if ($(window).width() < 768) {
            $htmlbody.addClass('scrollOff');
        }
    }

    menuToggle.on('click', function () {
        if ($(this).hasClass('openedMenuBtn')) {
            menuClose();
        } else {
            menuOpen();
        }
    });

    $(window).on( "resize", function() {
        if ($(window).width() > 767 && $('#newMobileMenu').hasClass('openedMenu')) {
            menuClose();
        }
    });

    $('#newMobileMenu .site-menu__close').on('click', function () {
        $(this).parent().removeClass('openedMenu');
        $('#menuToggle').removeClass('openedMenuBtn');
    });
    $('#search-auth .auth-arrow-wrapper').on('click', function () {
        $('.usrprof-menu').toggleClass('usrprof-menu-open');
        $('.usrprof-menu').toggleClass('usrprof-search-menu-open');
        $(this).find('.auth-arrow').toggleClass('active');
        if ($(window).width() < 768) {
            $htmlbody.toggleClass('scrollOff');
        }
    });
    /*$(".search-header #search-auth .auth-arrow").on('click' , function () {

    });*/
    if (window.matchMedia("(max-width: 767px)").matches) {
        $('.usrprof-menu').width($('body').width());
        $('.usrprof-menu').css('right', '-15px');
    }
    $('#main-search').on('input', function () {
        $(this).prev().hide();
    });
    // -section end: mobile menu

    // +section start: sliders

    // -section end: sliders

    // +section start: qna accordeon
    var qnaItem = $('.qna-item');
    qnaItem.on('click', function () {
        if ($(this).hasClass('qna-opened')) {
            $(this).removeClass('qna-opened');
            $(this).find('.qna-item__answer').slideUp('fast');
            return false;
        } else {
            qnaItem.removeClass('qna-opened');
            $(this).addClass('qna-opened');
            qnaItem.find('.qna-item__answer').slideUp('fast');
            $(this).find('.qna-item__answer').slideDown('fast');
            return false;
        }
    });
    // -section end: qna accordeon

    // +section start: inline modal
    var inlineModalClose = $('.inline-modal-close');
    inlineModalClose.on('click', function () {
        $(this).parent('.inline-modal-line').slideUp();
    });
    // -section end: inline modal

    // +section start: news sharing
    function initSharingIcons() {
        var newsSharingIcon = $('.news-sharing');
        newsSharingIcon.off().hover(
            function () {
                $(this).addClass('ns-opened');
            },
            function () {
                $(this).removeClass('ns-opened');
            }
        );
    }

    initSharingIcons();
    // -section end: news sharing

    // +section start: scroll to top

    var sttbtn = $('#scrolltop');

    function checkScroll() {
        if ($(window).scrollTop() > 300) {
            sttbtn.addClass('show-button');
        } else {
            sttbtn.removeClass('show-button');
        }
    }

    $window.on('scroll', function () {
        checkScroll();
    });

    checkScroll();

    sttbtn.on('click', function (e) {
        e.preventDefault();
        $htmlbody.animate({scrollTop: 0}, '300');
    });

    // -section end: scroll to top

    // +section start: scroll to next button

    var scrollNext = $('.scrollNext');
    scrollNext.on('click', function () {
        $htmlbody.animate({
            'scrollTop': $(this).closest('.section').next('.section').position().top
        });
    });

    // -section end: scroll to next button

    // ------------------------------
    // Resize & orientation change functions with 100ms timer

    function resizeElements() {
        // Some recalculations on resize
        // ...
    }

    $window.on('resize orientationchange', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(resizeElements, 10);
    });
    // Bind orientation change
    $window.on('orientationchange', function () {
        resizeElements();
    });
    // Fire once at startup
    resizeElements();

    var moreNewsButton = $('.more-news');
    var newsBlock = $('.news-block');

    function appendNewsBlock(html) {
        newsBlock.append(html);
        initSharingIcons();
    }

    function updateMoreNewsButton(currentPage, lastPage) {
        if (currentPage >= lastPage) {
            moreNewsButton.remove();
        } else {
            moreNewsButton.data('page', currentPage + 1);
        }
    }

    moreNewsButton.on('click', function (e) {
        moreNewsButton.attr('disabled', true);

        $.ajax({
            method: "GET",
            url: "/api/news",
            data: {
                page: moreNewsButton.data('page'),
            },
            success: function (response) {
                appendNewsBlock(response.html);
                updateMoreNewsButton(response.currentPage, response.lastPage);
                moreNewsButton.attr('disabled', false);
            }
        });
    });


})(jQuery);
